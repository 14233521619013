import { render, staticRenderFns } from "./Enquiry.vue?vue&type=template&id=0726cc50&"
import script from "./Enquiry.vue?vue&type=script&lang=js&"
export * from "./Enquiry.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports
import {QPage,QSpace,QCard,QTabs,QTab,QSeparator,QTabPanels,QTabPanel,QCardSection,QInput,QToggle,QIcon,QPopupProxy,QDate,QExpansionItem,QCardActions,QSpinnerTail,QList,QSelect,QCheckbox,QItem,QItemSection,QItemLabel,QTooltip,QBadge,QSkeleton} from 'quasar'
component.options.components = Object.assign(Object.create(component.options.components || null), component.options.components || {}, {QPage,QSpace,QCard,QTabs,QTab,QSeparator,QTabPanels,QTabPanel,QCardSection,QInput,QToggle,QIcon,QPopupProxy,QDate,QExpansionItem,QCardActions,QSpinnerTail,QList,QSelect,QCheckbox,QItem,QItemSection,QItemLabel,QTooltip,QBadge,QSkeleton})
