//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import bankEmployeeStore from '@/store/modules/bankEmployeeStore';
import configStore from '@/store/modules/configStore';
import enquiryStore from '@/store/modules/enquiryStore';
import fileStore from '@/store/modules/fileStore';
import EnquiryStatus from '@/components/upload/EnquiryStatus';
import CustomerBadge from '@/components/customer/CustomerBadge';
import FileStatus from '@/components/upload/FileStatus';
import PdfPreviewDialog from '@/components/PdfPreviewDialog';
import StatusChangeHistory from '@/components/upload/StatusChangeHistory';
import FilePatchStatusDialog from '@/components/upload/dialogs/FilePatchStatusDialog';
import FileService from '@/api/file.service';
import { date, Loading } from 'quasar';
import _ from 'lodash';
import downloadBlobResponse from '@/util/downloadBlobResponse';
import FileCreateDialog from '@/components/upload/dialogs/FileCreateDialog';
import FileTemplateDialog from '@/components/upload/dialogs/FileAndFileTemplateDialog';
import EnquiryActionBox from '@/components/upload/admin/EnquiryActionBox';
import EnquiryService from '@/api/enquiry.service';
import EnquiryDeleteDialog from '@/components/upload/dialogs/EnquiryDeleteDialog';
import EnquiryHardArchiveDialog from '@/components/upload/dialogs/EnquiryHardArchiveDialog';
import FileDeleteDialog from '@/components/upload/dialogs/FileDeleteDialog';
import Agree21ArchiveDialog from '@/components/upload/dialogs/Agree21ArchiveDialog';
import Agree21BatchArchiveResultDialog from '@/components/upload/dialogs/Agree21BatchArchiveResultDialog';
import EnquiryAttachmentDialog from '@/components/upload/dialogs/EnquiryAttachmentDialog';
import EnquiryAttachmentDeleteDialog from '@/components/upload/dialogs/EnquiryAttachmentDeleteDialog';
import EnquiryAttachmentsService from '@/api/enquiryAttachments.service';
import fileStatusConfig from '@/config/fileStatus.config';

export default {
    components: {
        EnquiryActionBox,
        FileStatus,
        CustomerBadge,
        EnquiryStatus,
        StatusChangeHistory,
    },
    data() {
        return {
            tabs: 'base',
            editMode: false,
            sortDocumentsAndAttachmentsBy: 'neueste zuerst',
            selectAllDocuments: false,
            batching: false,
            enquiryModel: {
                publicId: '',
                name: '',
                description: '',
                expiresAt: null,
                expires: false,
                requestedFiles: [],
                enquiryAttachments: [],
            },
        };
    },
    computed: {
        editable() {
            return ['draft'].indexOf(this.enquiry.status) !== -1;
        },
        archived() {
            return ['archived'].indexOf(this.enquiry.status) !== -1;
        },
        fileAddable() {
            return ['draft', 'open', 'completed'].indexOf(this.enquiry.status) !== -1;
        },
        fileIsEditable() {
            return (file) => {
                return ['requested', 'ready', 'problem'].indexOf(file.status) !== -1;
            };
        },
        fileIsDeletabel() {
            return (file) => {
                return ['draft'].indexOf(this.enquiry.status) !== -1 || ['requested', 'uploaded'].indexOf(file.status) !== -1;
            };
        },
        fileIsArchivable() {
            return (file) => {
                if (!this.agree21ArchiveFunctionIsActive) {
                    return false;
                }

                return !!(
                    file.fileOriginalName
                    && !file.agree21ArchivedAt
                    && ['verified', 'processed'].indexOf(file.status) !== -1
                );
            };
        },
        agree21ArchiveFunctionIsActive() {
            return this.config?.agree21?.uploadArchiveFilesActive;
        },
        enquiryAttachmentsAreActivated() {
            return this.config?.upload?.activateEnquiryAttachments;
        },
        mergedStatusChanges() {
            return [
                ...this.enquiryStatusChanges,
                ...this.enquiryFileStatusChanges,
            ].sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
        },
        contextForEnquiry() {
            const latestedStatusChange = this.mergedStatusChanges.find((statusChange) => statusChange['enquiry'] === this.enquiry['@id']);

            return latestedStatusChange?.context ?? {};
        },
        contextForFile() {
            return (requestedFile) => {
                const latestedStatusChange = this.mergedStatusChanges.find((statusChange) => statusChange['file'] === requestedFile['@id']);

                return latestedStatusChange?.context ?? {};
            };
        },
        sortDocumentsAndAttachmentsOptions() {
            return ['neueste zuerst', 'nach Status', 'zuletzt bearbeitet', 'nach Sortierindex'];
        },
        isValidToGetArchived() {
            return (fileOrAttachment) => {
                return this.agree21ArchiveFunctionIsActive && !fileOrAttachment.agree21ArchivedAt
                    && (fileOrAttachment.agree21ShelfId || (fileOrAttachment.agree21FormId && fileOrAttachment.agree21FormName));
            };
        },
        ...enquiryStore.mapState({
            enquiry: enquiryStore.STATE.CURRENT_ENQUIRY,
            enquiryStatusChanges: enquiryStore.STATE.CURRENT_ENQUIRY_STATUS_CHANGES,
            enquiryFileStatusChanges: enquiryStore.STATE.CURRENT_ENQUIRY_FILE_STATUS_CHANGES,
        }),
        ...configStore.mapState({
            config: configStore.STATE.CONFIG,
        }),
        ...bankEmployeeStore.mapGetters({
            hasRole: bankEmployeeStore.GETTERS.HAS_ROLE,
        }),
    },
    mounted() {
        this.init();
    },
    methods: {
        init() {
            this.enquiryModel = {
                ...this.enquiryModel,
                ..._.cloneDeep(this.enquiry),
                expires: !!this.enquiry.expiresAt,
            };
            this.sortDocumentsAndAttachments();
        },
        async refresh() {
            this.$q.loading.show();
            await enquiryStore.dispatch(enquiryStore.ACTIONS.FETCH_ENQUIRY, this.enquiry.id);
            await enquiryStore.dispatch(enquiryStore.ACTIONS.FETCH_ENQUIRY_STATUS_CHANGES, this.enquiry.id);
            await enquiryStore.dispatch(enquiryStore.ACTIONS.FETCH_ENQUIRY_FILE_STATUS_CHANGES, this.enquiry.id);
            this.$q.loading.hide();
        },
        parseToIsoDate(val, format = 'DD.MM.YYYY') {
            if (val) {
                return date.formatDate(date.extractDate(val, format), 'YYYY-MM-DD');
            }
        },
        toggleDatePicker() {
            this.$refs.qDateExpiresAt.toggle();
        },
        resetEnquiry() {
            this.init();
            this.editMode = false;
        },
        async saveEnquiry() {
            this.$q.loading.show();
            try {
                await enquiryStore.dispatch(enquiryStore.ACTIONS.PATCH_CURRENT_ENQUIRY, {
                    id: this.enquiry.id,
                    enquiry: {
                        ...this.enquiryModel,
                        expiresAt: this.enquiryModel.expires ? this.enquiryModel.expiresAt : null,
                        customer: this.enquiryModel.customer['@id'],
                    },
                });
            } catch (error) {
                this.$handleError(error);
            }
            this.$q.loading.hide();
            this.init();
            this.editMode = false;
        },
        deleteEnquiry() {
            this.$q.dialog({
                parent: this,
                component: EnquiryDeleteDialog,
                publicId: this.enquiry.publicId,
            }).onOk(async () => {
                this.$q.loading.show();
                try {
                    await EnquiryService.deleteEnquiry(this.enquiry.id);
                    await this.$router.go(-1);
                } catch (error) {
                    this.$handleError(error);
                }
                this.$q.loading.hide();
            });
        },
        async addFile() {
            this.$q.dialog({
                component: FileCreateDialog,
                parent: this,
            }).onOk(async (payload) => {
                this.$q.loading.show();
                try {
                    if (payload.fileTemplate) {
                        await FileService.createFileByFileTemplate({
                            enquiry: this.enquiry['@id'],
                            fileTemplate: payload.fileTemplate,
                        });
                    } else {
                        await FileService.createFile({
                            enquiry: this.enquiry['@id'],
                            name: payload.name,
                        });
                    }
                    await this.refresh();
                } catch (error) {
                    this.$handleError(error);
                }
                this.$q.loading.hide();
                this.init();
            });
        },
        async addEnquiryAttachment() {
            this.$q.dialog({
                component: EnquiryAttachmentDialog,
                type: 'create',
                parent: this,
            }).onOk(async (payload) => {
                this.$q.loading.show();
                try {
                    const { data: enquiryAttachment } = await EnquiryAttachmentsService.createEnquiryAttachment({
                        enquiry: this.enquiry['@id'],
                        ...payload.enquiryAttachment,
                    });
                    await EnquiryAttachmentsService.uploadEnquiryAttachment(enquiryAttachment, payload.file);
                    await this.refresh();
                } catch (error) {
                    this.$handleError(error);
                }
                this.$q.loading.hide();
                this.init();
            });
        },
        editFile(requestedFile) {
            this.$q.dialog({
                parent: this,
                component: FileTemplateDialog,
                value: requestedFile,
                entity: 'File',
                type: 'edit',
            }).onOk(async (payload) => {
                this.$q.loading.show();
                if (payload) {
                    try {
                        await FileService.patchFile(requestedFile.id, payload);
                        await this.refresh();
                    } catch (error) {
                        this.$handleError(error);
                    }
                }
                this.$q.loading.hide();
                await this.refresh();
                this.init();
            });
        },
        editEnquiryAttachment(enquiryAttachment) {
            this.$q.dialog({
                parent: this,
                component: EnquiryAttachmentDialog,
                type: 'edit',
                enquiryAttachment: enquiryAttachment,
            }).onOk(async (payload) => {
                this.$q.loading.show();
                try {
                    await EnquiryAttachmentsService.patchEnquiryAttachment(enquiryAttachment, payload.enquiryAttachment);
                    await this.refresh();
                } catch (error) {
                    this.$handleError(error);
                }
                this.$q.loading.hide();
                this.init();
            });
        },
        viewFile(file) {
            this.$q.dialog({
                parent: this,
                component: PdfPreviewDialog,
                url: `/api/files/${file.id}/preview`,
            });
        },
        viewEnquiryAttachment(enquiryAttachment) {
            this.$q.dialog({
                parent: this,
                component: PdfPreviewDialog,
                url: `/api/enquiry-attachments/${enquiryAttachment.id}/preview`,
            });
        },
        async downloadRequestedFile(requestedFile) {
            Loading.show();
            const response = await FileService.downloadSummaryAsPdf(requestedFile.id);
            downloadBlobResponse(response);
            Loading.hide();
        },
        async downloadEnquiryAttachment(enquiryAttachment) {
            Loading.show();
            const response = await EnquiryAttachmentsService.downloadEnquiryAttachment(enquiryAttachment);
            downloadBlobResponse(response);
            Loading.hide();
        },
        changeFileStatus(requestedFile, newStatus) {
            this.$q.dialog({
                component: FilePatchStatusDialog,
                file: requestedFile,
                newStatus: newStatus,
                parent: this,
            }).onOk(async (payload) => {
                this.$q.loading.show();
                try {
                    await fileStore.dispatch(fileStore.ACTIONS.PATCH_FILE, {
                        id: requestedFile.id,
                        patchData: {
                            status: newStatus,
                            __context: {
                                message: payload.message,
                            },
                        },
                    });
                    await enquiryStore.dispatch(enquiryStore.ACTIONS.FETCH_ENQUIRY, this.enquiry.id);
                    await enquiryStore.dispatch(enquiryStore.ACTIONS.FETCH_ENQUIRY_FILE_STATUS_CHANGES, this.enquiry.id);
                    this.enquiryModel = {
                        ...this.enquiryModel,
                        ..._.cloneDeep(this.enquiry),
                        expires: !!this.enquiry.expiresAt,
                    };
                } catch (error) {
                    this.$handleError(error);
                }
                this.$q.loading.hide();
            });
        },
        deleteFile(requestedFile) {
            this.$q.dialog({
                parent: this,
                component: FileDeleteDialog,
                name: requestedFile.name,
            }).onOk(async () => {
                this.$q.loading.show();
                try {
                    await FileService.deleteFile(requestedFile.id);
                    await this.refresh();
                } catch (error) {
                    this.$handleError(error);
                }
                this.$q.loading.hide();
                this.init();
            });
        },
        deleteEnquiryAttachment(enquiryAttachment) {
            this.$q.dialog({
                parent: this,
                component: EnquiryAttachmentDeleteDialog,
                name: enquiryAttachment.name,
            }).onOk(async () => {
                this.$q.loading.show();
                try {
                    await EnquiryAttachmentsService.deleteEnquiryAttachment(enquiryAttachment);
                    await this.refresh();
                } catch (error) {
                    this.$handleError(error);
                }
                this.$q.loading.hide();
                this.init();
            });
        },
        async agree21ArchiveFile(requestedFile) {
            this.$q.dialog({
                parent: this,
                component: Agree21ArchiveDialog,
                entity: requestedFile,
                entityKind: 'File',
                customer: this.enquiryModel.customer,
            }).onOk(async (payload) => {
                this.$q.loading.show();
                try {
                    await FileService.agree21Archive(requestedFile.id, payload.entity, payload.customer);
                    this.$notify('Dokument wurde erfolgreich an agree21 zur Archivierung übergeben.', 'positive');
                } catch (error) {
                    this.$handleError(error);
                }
                await this.refresh();
                this.$q.loading.hide();
                this.init();
            });
        },
        async agree21ArchiveEnquiryAttachment(enquiryAttachment) {
            this.$q.dialog({
                parent: this,
                component: Agree21ArchiveDialog,
                entity: enquiryAttachment,
                entityKind: 'EnquiryAttachment',
                customer: this.enquiryModel.customer,
            }).onOk(async (payload) => {
                this.$q.loading.show();
                try {
                    await EnquiryAttachmentsService.agree21Archive(enquiryAttachment.id, payload.entity, payload.customer);
                    this.$notify('Bankdokument wurde erfolgreich an agree21 zur Archivierung übergeben.', 'positive');
                } catch (error) {
                    this.$handleError(error);
                }
                await this.refresh();
                this.$q.loading.hide();
                this.init();
            });
        },
        async sortDocumentsAndAttachments() {
            switch (this.sortDocumentsAndAttachmentsBy) {
                case 'zuletzt bearbeitet':
                    this.enquiryModel.requestedFiles.sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt));
                    this.enquiryModel.enquiryAttachments.sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt));
                    break;
                case 'nach Sortierindex':
                    this.enquiryModel.requestedFiles.sort((a, b) => a.sortIndex - b.sortIndex);
                    break;
                case 'nach Status':
                    this.enquiryModel.requestedFiles.sort((a, b) => fileStatusConfig[a.status].sortOrder - fileStatusConfig[b.status].sortOrder);
                    this.enquiryModel.enquiryAttachments.sort((a, b) => b.public - a.public);
                    break;
                default:
                    this.enquiryModel.requestedFiles.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
                    this.enquiryModel.enquiryAttachments.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
            }
        },
        hardArchiveEnquiry() {
            this.$q.dialog({
                parent: this,
                component: EnquiryHardArchiveDialog,
                publicId: this.enquiry.publicId,
            }).onOk(async () => {
                this.$q.loading.show();
                try {
                    await EnquiryService.hardArchiveEnquiry(this.enquiry.id);
                    this.$notify('Vorgang wurde erfolgreich archiviert.', 'positive');
                    await this.$router.go(-1);
                } catch (error) {
                    this.$handleError(error);
                }
                this.$q.loading.hide();
            });
        },
        toggleSingleCheckbox(file) {
            for (const requestedFile of this.enquiryModel.requestedFiles.filter(r => r.id === file.id)) {
                if (requestedFile.isSelected === undefined) {
                    this.$set(requestedFile, 'isSelected', false);
                }
                requestedFile.isSelected = !requestedFile.isSelected;
            }
        },
        toggleSingleCheckboxAttachment(enquiryAttachment) {
            for (const attachment of this.enquiryModel.enquiryAttachments.filter(r => r.id === enquiryAttachment.id)) {
                if (attachment.isSelected === undefined) {
                    this.$set(attachment, 'isSelected', false);
                }
                attachment.isSelected = !attachment.isSelected;
            }
        },
        toggleAllPossibleCheckboxes() {
            this.selectAllDocuments = !this.selectAllDocuments;
            this.enquiryModel.requestedFiles.forEach(requestedFile => {
                if (requestedFile.isSelected === undefined) {
                    this.$set(requestedFile, 'isSelected', false);
                }
                if (this.fileIsArchivable(requestedFile)) {
                    requestedFile.isSelected = this.selectAllDocuments;
                }
            });
            this.enquiryModel.enquiryAttachments.forEach(attachment => {
                if (attachment.isSelected === undefined) {
                    this.$set(attachment, 'isSelected', false);
                }
                if (this.isValidToGetArchived(attachment)) {
                    attachment.isSelected = this.selectAllDocuments;
                }
            });
        },
        async batchArchive() {
            this.batching = true;
            this.$q.loading.show();
            var batchResult = {
                files: {
                    success: [],
                    failed: [],
                },
                attachments: {
                    success: [],
                    failed: [],
                },
            };
            for (const file of this.enquiryModel.requestedFiles.filter(r => r.isSelected)) {
                try {
                    await FileService.agree21Archive(file.id, file, this.enquiryModel.customer);
                    batchResult.files.success.push(file);
                } catch (error) {
                    batchResult.files.failed.push(file);
                }
            }
            for (const attachment of this.enquiryModel.enquiryAttachments.filter(a => a.isSelected)) {
                try {
                    await EnquiryAttachmentsService.agree21Archive(attachment.id, attachment, this.enquiryModel.customer);
                    batchResult.attachments.success.push(attachment);
                } catch (error) {
                    batchResult.attachments.failed.push(attachment);
                }
            }

            // deselect all
            this.selectAllDocuments = false;
            this.enquiryModel.requestedFiles.forEach(requestedFile => requestedFile.isSelected = false);
            this.enquiryModel.enquiryAttachments.forEach(attachment => attachment.isSelected = false);

            this.batching = false;
            this.$q.loading.hide();

            // results dialog
            this.$q.dialog({
                component: Agree21BatchArchiveResultDialog,
                parent: this,
                batchResult: batchResult,
            }).onOk(async () => {
                try {
                    await this.refresh();
                } catch (error) {
                    this.$handleError(error);
                }
            });
        },
    },
    async beforeRouteEnter(to, from, next) {
        if (!bankEmployeeStore.getters[bankEmployeeStore.GETTERS.IS_LOGGED_IN]) {
            return next({ name: 'admin-login' });
        }

        try {
            await enquiryStore.dispatch(enquiryStore.ACTIONS.FETCH_ENQUIRY, to.params.id);
            await enquiryStore.dispatch(enquiryStore.ACTIONS.FETCH_ENQUIRY_STATUS_CHANGES, to.params.id);
            await enquiryStore.dispatch(enquiryStore.ACTIONS.FETCH_ENQUIRY_FILE_STATUS_CHANGES, to.params.id);
            next();
        } catch (error) {
            enquiryStore.commit(enquiryStore.MUTATIONS.RESET_CURRENT_ENQUIRY);
            enquiryStore.commit(enquiryStore.MUTATIONS.RESET_CURRENT_ENQUIRY_STATUS_CHANGES);
            enquiryStore.commit(enquiryStore.MUTATIONS.RESET_CURRENT_ENQUIRY_FILE_STATUS_CHANGES);
            next(vm => vm.$handleError(error));
        }
    },
};
